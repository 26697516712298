import React from "react";
import { useNavigate } from "react-router-dom";
import store from "../../store/store";
import config from "../../config";
import hangup from "../../resources/images/hangup.png";
import { Tooltip } from "react-tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as webRTCHandler from "../../utils/webRTCHandler";
import { connectWithSocketIOServer } from "../../utils/wss";

const LeaveRoomButton = () => {
  const navigate = useNavigate();

  const saveRecording = async () => {
    webRTCHandler.stopSaveLocalStream().then((status) => {
      console.log(status);
      webRTCHandler.stopSaveRemoteStream().then((status) => {
        console.log(status);
        exitCall();
      });
    });
    // await
    // new Promise((resolve) => setTimeout(resolve, 4000));
    // console.log("Called functiion...");
  };

  const exitCall = () => {
    const siteUrl = window.location.origin;
    const authData = store.getState().cofferAuthData;
    const cofferToken = store.getState().cofferToken;
    const myJid = store.getState().fromJid;
    const identity = store.getState().identity;
    const loginType = store.getState().loginType;
    const hideClient = store.getState().hideClient;
    // console.log("Disconnecting routine....");
    window.location.href = `${siteUrl}?logintype=${encodeURIComponent(
      loginType
    )}&token=${cofferToken}&jid=${encodeURIComponent(
      myJid
    )}&name=${encodeURIComponent(identity)}&hideclient=${hideClient}`;
    // webRTCHandler.disconnectUser();
    // navigate(`/`);
  };

  const handleRoomDisconnection = () => {
    if (
      store.getState().isRoomHost === true &&
      store.getState().isRecordingOn === true
    ) {
      // window.confirm(`Recording on ${store.getState().isRecordingOn}`);
      // window.confirm(`Room Host on ${store.getState().isRoomHost}`);
      confirmAlert({
        title: "Recording",
        message: `Recording is not saved, do you want to save recording before exiit`,
        buttons: [
          {
            label: "Yes",
            onClick: async () => await saveRecording(),
          },
          {
            label: "No",
            onClick: () => exitCall(),
          },
        ],
        closeOnClickOutside: false,
      });
    } else {
      console.log("Leaving toom...");
      exitCall();
    }
  };
  return (
    <div className="video_button_container">
      <Tooltip id="my-tooltip" />
      <img
        className="video_button_hangup_image"
        onClick={handleRoomDisconnection}
        src={hangup}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Leave chat"
      ></img>
      {/* <button className="video_button_end" onClick={handleRoomDisconnection}>
        Leave Meeting
      </button> */}
    </div>
  );
};

export default LeaveRoomButton;
